import React from 'react'

const Footer = () => {
	return (
		<footer className='contact-footer container'>
			<>
				©<span className='current-date'>2022</span> KA-BE kovo s.r.o
			</>
		</footer>
	)
}

export default Footer
